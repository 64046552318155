// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as applicationActions from 'common/modules/application/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    ApplicationLoginLinkType,
    IApplicationResponse,
} from 'common/api/resources/Application';
import {
    addToPaginated,
    appendToPaginated,
    deleteFromPaginated,
    updateAllInPaginated,
    updateInPaginated,
    updateInPaginatedBy,
} from 'common/reducers/list';
import { OsFirmware } from 'common/api/resources/ComputeResourceVm';

interface IApplication {
    list: IPaginateApiResponse<IApplicationResponse[]>;
    item: IApplicationResponse;
}

export type ApplicationAction = ActionType<typeof applicationActions>;
export type ApplicationState = IApplication;

export const initialApplicationState: IApplicationResponse = {
    id: 0,
    cloud_init_version: 'v2',
    icon: null,
    is_default: false,
    name: '',
    url: '',
    external_integration: null,
    login_link: {
        type: ApplicationLoginLinkType.URL,
        content: 'https://{{ hostname }}/?login={{ login }}&password={{ password }}',
    },
    user_data_template: `#cloud-config
runcmd:
  - echo "echo "Hello {{ login }}"" > /root/{{ hostname }}.sh
  - [ chmod, +x, "/root/{{ hostname }}.sh" ]`,
    json_schema: `{
  "title": "Simple application",
  "description": "A simple application form.",
  "type": "object",
  "required": [
    "login",
    "password"
  ],
  "properties": {
    "login": {
      "type": "string",
      "title": "Login",
      "default": "Chuck"
    },
    "password": {
      "type": "string",
      "title": "Password"
    }
  }
}`,
    is_loading: false,
    is_deleting: false,
    is_visible: true,
    is_buildin: false,
    position: 0,
    available_plans: [],
    vs_firmware: OsFirmware.BIOS,
};

export default combineReducers<ApplicationState, ApplicationAction>({
    list: (state = paginateInitialState, action: ApplicationAction) => {
        switch (action.type) {
        case getType(applicationActions.appendApplications):
            return appendToPaginated(state, action.payload);
        case getType(applicationActions.addApplicationItem):
            return addToPaginated(state, action.payload);
        case getType(applicationActions.setApplicationList):
            return action.payload;
        case getType(applicationActions.removeApplicationItem):
            return deleteFromPaginated(state, action.payload);
        case getType(applicationActions.updateApplicationItem):
            return updateInPaginated(state, action.payload);
        case getType(applicationActions.updateApplicationItemPosition):
            return updateInPaginated(state, action.payload);
        case getType(applicationActions.clearDefault):
            return updateAllInPaginated(state, { is_default: false });
        case getType(applicationActions.setApplicationItemIsLoading):
            return updateInPaginatedBy(state, action.payload, { is_loading: true });
        case getType(applicationActions.unsetApplicationItemIsLoading):
            return updateInPaginatedBy(state, action.payload, { is_loading: false });
        case getType(applicationActions.setApplicationItemIsDeleting):
            return updateInPaginated(state, action.payload);
        case getType(applicationActions.removeApplicationItems):
            const applications = state.data.reduce((prev: IApplicationResponse[], current: IApplicationResponse) => {
                if (action.payload.includes(current.id)) {
                    return prev;
                }

                return [...prev, current];
            }, []);

            return {
                ...state,
                data: applications,
            };
        default:
            return state;
        }
    },
    item: (state = initialApplicationState, action) => {
        switch (action.type) {
        case getType(applicationActions.setApplicationItem):
            return action.payload;
        case getType(applicationActions.unsetApplicationItem):
            return { ...initialApplicationState };
        default:
            return state;
        }
    },
});
